/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useEffect, useState } from 'react';
import { ChevronDown24, ChevronUp24 } from '@carbon/icons-react/es';
import * as S from './Menu.styles';
import { useServiceContext } from '@exo/frontend-features-insurance-products-services-logic';
import { useHistory } from 'react-router-dom';

export const Menu = ({ items, activeUrl }: Props) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [activeUrlParent, setActiveUrlParent] = useState('');
  const serviceContext = useServiceContext();
  const history = useHistory();

  const getActiveUrlParent = () => {
    let url: string = 'no-active';
    for (const i of items) {
      const parentUrl = i.url;
      const children = i?.items ?? [];
      if (children?.length == 0) url = parentUrl;
      for (const child of children) {
        if (activeUrl?.includes(child.url)) {
          return parentUrl;
        }
      }
    }
    return url;
  };

  useEffect(() => {
    if (items) {
      const activeParent = getActiveUrlParent();
      if (!activeParent.includes('#') && !activeUrl?.includes(activeParent)) {
        setExpanded([items[0]?.url]);
        return;
      }

      setActiveUrlParent(activeParent);
      setExpanded([activeParent]);
    }
  }, [activeUrl]);

  const isExpanded = (s: Level1Item) => expanded.includes(s.url);
  /* c8 ignore next 4 */
  const toggle = (s: Level1Item) => {
    setExpanded([]);
    setExpanded(ex => (isExpanded(s) ? ex.filter(e => e !== s.url) : [...ex, s.url]));
  };

  const asignClassName = (l1: Level1Item) => {
    let classNames: string[] = [];
    if (activeUrl?.includes(activeUrlParent)) {
      classNames.push('isActive');
    }
    if (activeUrlParent === l1.url || activeUrl == l1.url) {
      classNames.push('selected-parent');
      if (l1?.items?.length == 0) classNames.push('no-child');
    }
    //crazy case where we don't have children
    if (
      !l1.url.includes('#') &&
      (activeUrl?.includes(l1.url + '/') || activeUrlParent.includes(l1.url + '/'))
    ) {
      classNames.push('selected-parent');
      if (l1?.items?.length == 0) classNames.push('no-child');
    }
    return classNames.join(' ');
  };

  const onFunctionClick = (e, item: ChildItem | undefined, l1: Level1Item | undefined) => {
    e.preventDefault();
    /* c8 ignore next 4 */
    if (l1 && l1?.items?.length > 0 && !item) {
      toggle(l1!);
      return;
    }
    serviceContext!.set({
      clickedFunctionName: item?.label ?? l1?.title,
      parentFunctionName: item?.parentName,
      functionType: item?.type,
      previousSelectedTab: '',
      clickedFunctionId: item?.id ?? l1?.id,
      businessAreaId: '',
      selectedInsurers: [],
      preselectedInsurers: [],
      selectedBusiness: [],
      businessAreaNames: [],
      insurersTreeData: undefined,
      businessTreeData: undefined,
      templateId: '',
      templateLevel: '',
      selectedIBLs: [],
      serviceKeys: [],
      xFormData: []
    });
    //we will push to history only after we know that the context has changed
    serviceContext!.get().then(() => {
      const url = item?.url ?? l1?.url;
      history.push(url!);
    });
  };

  const checkActiveChildren = (selectedUrl: string) => {
    const selectedUrlElements: string[] = selectedUrl.split('/');
    const selectedUrlLastElement: number = selectedUrlElements?.length - 1;
    const selectedUrlParentElement: number = selectedUrlElements?.length - 2;
    const activeUrlElements: string[] = activeUrl?.split('/')!;

    return (
      activeUrlElements[selectedUrlLastElement] === selectedUrlElements[selectedUrlLastElement] &&
      activeUrlElements[selectedUrlParentElement] === selectedUrlElements[selectedUrlParentElement]
    );
  };
  return (
    <>
      {items?.length > 0 &&
        items?.map(l1 => (
          <S.Menu key={l1?.id || l1?.title}>
            <S.Header className={asignClassName(l1)} onClick={() => toggle(l1)}>
              {!l1?.url.includes('#') ? (
                /* c8 ignore next 5  */
                <S.TitleURL
                  href={l1.isProdServices ? '#' : l1.url}
                  data-cy={l1.title ?? l1.url}
                  onClick={e => (l1.isProdServices ? onFunctionClick(e, undefined, l1) : undefined)}
                >
                  {l1.title ?? l1.url}
                </S.TitleURL>
              ) : (
                <S.Title data-cy={l1.title}>{l1.title}</S.Title>
              )}
              <S.Icon data-cy={'expand-shrink-btn'}>
                {l1?.items?.length > 0 && (isExpanded(l1) ? <ChevronUp24 /> : <ChevronDown24 />)}
              </S.Icon>
            </S.Header>
            <S.Content isExpanded={isExpanded(l1)}>
              <S.Items>
                {l1.items?.map(item =>
                  /* c8 ignore next 7  */
                  item.type !== undefined ? (
                    <S.ALink
                      className={
                        checkActiveChildren(item.url)
                          ? `isActive ${item.label.split(' ')?.join('-')}`
                          : `${item.label.split(' ')?.join('-')}`
                      }
                      key={item?.id || item?.url}
                      onClick={e => onFunctionClick(e, item, l1)}
                      data-testid="leftMenuItemWithContext"
                      data-cy={item.label.split(' ')?.join('-')}
                    >
                      {item.label}
                    </S.ALink>
                  ) : (
                    <S.Link
                      className={
                        checkActiveChildren(item.url)
                          ? `isActive ${item.label.split(' ')?.join('-')}`
                          : `${item.label.split(' ')?.join('-')}`
                      }
                      to={item.url}
                      key={item?.id || item?.url}
                      onclick={() => toggle(l1)}
                      data-testid="leftMenuItem"
                      data-cy={item.label.split(' ')?.join('-')}
                    >
                      {item.label}
                    </S.Link>
                  )
                )}
              </S.Items>
            </S.Content>
          </S.Menu>
        ))}
    </>
  );
};

export type Level1Item = {
  title: string;
  url: string;
  isExpanded?: boolean;
  id?: string;
  type?: string;
  isProdServices?: boolean;
  items: {
    url: string;
    label: string;
    type?: string;
    id: string;
  }[];
};

type Props = {
  activeUrl?: string;
  items: Level1Item[];
};

type ChildItem = {
  label: string;
  url: string;
  items?: ChildItem[];
  type?: string;
  parentName?: string;
  id?: string;
};
