/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { useTheme } from 'styled-components';
import { ExtensionNode } from '@exo/frontend-common-app-shell';
import { Action } from '@exo/frontend-components-base';
import { ChromeConfig } from '../../chromeConfig';
import { Masthead } from '@exo/frontend-components-core';
import { useLocation } from 'react-router-dom';
import menuItems from './menuItems.json';
import { usePolarisSessionContext } from '@exo/frontend-common-polaris-session-context';

const setWelcomeItem = (rightMenu, session) => {
  return rightMenu.map(item => {
    if (item.label?.includes('{name}')) {
      item.label = item.label.replace('{name}', session.name);
    }
    return item;
  });
};

export const Header = ({ config, isAuth }: { config: ChromeConfig } & { isAuth?: boolean }) => {
  const currentTheme = useTheme() as any;
  const location = useLocation();
  const { pathname } = location;
  const menuKey = pathname.split('/')[1];
  const session = usePolarisSessionContext();
  const roles = session?.roles[0];
  // in case there is a role, we get items from menuItems.json. otherwise, get menu from applications.ts
  const links = roles ? menuItems.topMenu[roles] : config.header.fixedLinks;
  const rightMenu = roles
    ? setWelcomeItem(menuItems.rightMenu, session)
    : menuItems.defaultRightMenu;
  const rightMenuKey = pathname.split('/')[2];
  const aboutObj = config.header.fixedLinks ? config.header.fixedLinks[2] : { href: '', label: '' };

  return (
    <>
      <Masthead>
        <Masthead.Header
          logo={
            <Action
              href={config.header.homeLink}
              icon={<currentTheme.static.Logo />}
              isUserBlocked={session?.blockedUser}
            />
          }
          navigation={
            <>
              {links?.map(l => (
                <Action
                  key={l.href}
                  href={l.href}
                  label={l.label}
                  isActive={l.href?.includes(menuKey)}
                  target={l.target}
                  isUserBlocked={session?.blockedUser}
                />
              ))}
              {roles && (
                <Action
                  href={aboutObj.href}
                  label={aboutObj.label}
                  target={aboutObj.target}
                  isUserBlocked={session?.blockedUser}
                />
              )}
            </>
          }
          tertiary={
            <>
              {rightMenu.map(l => (
                <React.Fragment key={l.label}>
                  {l.href !== '#' ? (
                    <Action
                      key={l.href}
                      href={isAuth ? config?.header?.homeLink + l.href : l.href}
                      label={l.label}
                      isActive={l.href.includes(rightMenuKey)}
                      isUserBlocked={session?.blockedUser}
                    />
                  ) : (
                    <span className="welcome-user" key={l.label} title={session.roleName}>
                      {l.label}
                    </span>
                  )}
                </React.Fragment>
              ))}
            </>
          }
        />
      </Masthead>

      <ExtensionNode extensions={config.header.extensions.extraHeaders} />
    </>
  );
};
